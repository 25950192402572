@import './mixins.scss';

p.title {
  @include font-label;
  @include mb(2.5);
}

.products {
  @include mb(3);
}

.product {
  @include flex(row, center, space-between);

  &:not(:last-of-type) {
    @include mb(2);
  }

  &__left {
    @include flex(row, center, flex-start);
  }

  &__image {
    @include mr(2);
    height: 65px;
    width: 65px;
    min-height: 65px;
    min-width: 65px;
    border-radius: 3px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  &__name {
    @include mb(0.6);
    font-size: 14px;
  }

  &__subtotal {
    font-size: 14px;
  }

  &__quantityPrice {
    @include font-label;
    font-size: 13px;
    font-weight: normal;
  }
}

.amount {
  @include flex(row, center, space-between);
  @include mb(1.5);

  &__label,
  &__value {
    font-size: 14px;
  }
}
.total {
  @include mb(0);
  .amount__label,
  .amount__value {
    font-weight: bold;
    font-size: 15px;
  }
}