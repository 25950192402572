@import './mixins.scss';

.wrapper {
  width: 100%;
  @include flex;
  @include py(4);
}

.image {
  height: 70px;
  width: auto;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  &[data-name="Cristiano"]{
    height: 40px;
  }
  &[data-name="Label Rose"]{
    height: 50px;
  }
  &[data-name="Grela Parfum"]{
    height: 60px;
  }
}
