@import './mixins.scss';

.wrapper {
  @include mb(6);
}

.title {
  @include font-label;
  @include mb(2);
}

.option {
  font-size: 14px;

  &:not(:last-of-type) {
    @include mb(2);
  }
}

.optionInner {
  @include flex(row, center, space-between);
  width: 100%;
}