@import './mixins.scss';

p {
  margin: 0;
}

.wrapper {
  @include pa(8, 0);
  * {
    box-sizing: border-box;
  }

  @media screen and (max-width: 768px) {
    @include pa(3, 2);
    @include pb(8);
  }
}

.user {
  @include mb(6);
}

.header {
  @include mb(3);

  &__closeBtn {
    @include button-incognito;
    @include flex(row, center, flex-start);
    @include pa(0.5, 0);
    display: inline-flex;
    font-size: 14px;
    color: black;
    opacity: 0.5;
    line-height: 1;
  }

  &__arrow {
    @include mr(0.5);
    @include mb(0.1);
    height: 16px;
    width: 16px;
    background-size: cover;
    background-position: left center;
    background-repeat: no-repeat;
  }
}

.borderWrapper {
  @include mb(2);
  border: 1px solid #ddd;
  padding: 30px;
  border-radius: 5px;
}

.submit {
  @include button-incognito;
  @include py(2);
  width: 100%;
  font-size: 16px;
  background: black;
  color: white;
  font-weight: bold;

  &:disabled {
    background: rgba(0, 0, 0, 0.5) !important;
    cursor: not-allowed;
  }
}
