@import './mixins.scss';

.heading {
  @include flex(row, center, space-between);
  @include mb(2.5);
}

.title,
.label {
  @include font-label;
}
.label {
  @include flex;
  font-weight: normal;
}
.lock {
  @include mr(0.3);
  display: inline-block;
  height: 13px;
  width: 13px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.payment {
  &:not(:last-of-type) {
    @include mb(2);
  }
}

.paymentInner {
  @include flex(row, center, space-between);
  width: 100%;
  font-size: 14px;
}

.paymentIcons {
  @include flex(row, center, flex-start);
}

.paymentIcon {
  height: 24px;
  width: 35px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid #DFDFDE;
  border-radius: 3px;

  &:not(:last-of-type) {
    @include mr(0.3);
  }

  &--sepa,
  &--amazonpay,
  &--applepay,
  &--googlepay,
  &--sofort {
    border: none;
  }

  &--bankTransfer {
    background-size: 65%;
  }
}

.visa {
  background-size: 95%;
}