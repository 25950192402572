@import '../../styles/mixins.scss';

@keyframes spin {to{transform: rotate(1turn)}}

.wrapper {
  @include flex(column, center, flex-start);
  height: 100vh;
  width: 100vw;

  * {
    box-sizing: border-box;
  }

  p {
    font-size: 1rem;
  }
}

.inner {
  @include flex(column, center, flex-start);
  width: 100%;
  height: 100%;
  max-width: 420px;
}

.lock {
  display: inline-block;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.top {
  @include flex(row, center, space-between);
  width: 100%;
  position: relative;
  padding: 1rem 0.8rem;

  @media screen and (min-width: 768px) {
    padding: 1rem;
  }

  &--center {
    justify-content: center;
  }

  .logoWrapper {
    @include flex();
  }

  .logo {
    height: 30px;
    width: auto;

    @media screen and (min-width: 768px) {
      height: 35px;
    }
  }

  .secureText {
    @include flex();
  }

  .lock {
    height: 1rem;
    width: 1rem;
    margin-right: 0.3rem;
  }

  .border {
    position: absolute;
    height: 1px;
    width: 100vw;
    background-color: #d9d9d9;
    bottom: 0;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
  }
}

.bottom {
  @include flex(column, center, center);
  flex-grow: 1;
  padding-bottom: 40vh;

  .loading {
    @include flex();
    position: relative;
    width: 4rem;
    height: 4rem;
    margin-bottom: 1.5rem;
  }

  .lock {
    position: relative;
    height: 1.5rem;
    width: 1.5rem;
    z-index: 1;
  }

  .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(#d9d9d9, 0.7);
    border-radius: 100%;
    animation: pulse 1.5s infinite;
    z-index: 0;
  }

  p {
    line-height: 1.5;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}
