@import '../../styles/mixins.scss';

.expressModal {
  width: 600px;
  @include px(4);
  @include pt(4);
  @include pb(2);
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  position: relative;
  background-color: #F6F7FB;
  box-sizing: border-box;
  border-radius: 20px;
  overflow: hidden;
  position: relative;

  @media screen and (max-width: 768px) {
    min-height: 100vh;
    min-width: 100vw;
    @include px(2);
    position: fixed;
    top: 0;
    left: 0;
    border-radius: 0;
  }

  &__container {
    position: relative;
    z-index: 1;
  }

  &__close {
    position: absolute;
    top: -10px;
    right: 0;
    height: 35px;
    width: 35px;
    border-radius: 50%;
    @include flex(row, center, center);
    transition: background-color 0.5s ease;
    &:hover {
      cursor: pointer;
      background-color: #E5E5E5;
    }
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
  &__content {
    @include pa(2, 2);
    background-color: #fff;
    @include mt(3.5);
    border-radius: 20px;
    @include flex(column, center, center);
  }

  &__action {
    @include flex(row, center, center);
    @include mt(3);
  }
}

.pinkBall {
  background-color: #F9DCDE;
  width: 2000px;
  height: 2000px;
  position: absolute;
  top: -680px;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}

.alert {
  position: relative;
  @include pa(1, 2);
  @include mb(3);
  border: 1px solid transparent;
  border-radius: 5px;
  font-size: 14px;
}

.alert__danger {
  @extend .alert;
  color: #823040;
  background-color: #fedee5;
  border-color: #fed1da;
}

.link:hover {
  cursor: pointer;
}

.terms {
  max-width: 323px;
  margin: 20px auto 0;
  color: #8A8A8D;
  a {
    color:#8A8A8D
  }
}
