@import '../mixins.scss';

.radio {
  @include flex(row, center, flex-start);
  width: 100%;
  cursor: pointer;
}

.selected .radioBox {
  border: 6px solid black;
}

.align-top {
  align-items: flex-start;
}

.align-bottom {
  align-items: flex-end;
}

.radioBox {
  @include flex;
  display: inline-flex;
  height: 1.3rem;
  width: 1.3rem;
  min-height: 1.3rem;
  min-width: 1.3rem;
  border: 2px solid black;
  border-radius: 50%;
  margin-right: 0.5rem;
  transition: all 0.3s ease-in-out;
}

.radioInnerBox {
  min-height: 0.7rem;
  min-width: 0.7rem;
  height: 0.7rem;
  width: 0.7rem;
  circle {
    fill: black;
  }
}