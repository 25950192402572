@import './mixins.scss';

.footer {
  @include flex(row, center, space-between);
  @include pa(3, 2);
  @include mb(4);
  font-size: 14px;
  color: #80898C;
  font-weight: normal;

  &__links {
    @include flex(row, center, flex-end);

    a {
      display: inline-block;
      color: #80898C;

      &:hover {
        color: #777;
      }

      &:visited {
        color: #80898C;
      }

      &:hover {
        color: #80898C;
      }

      &:not(:last-of-type) {
        @include mr(1);
      }
    }
  }
}