@mixin flex(
  $flex-direction: row,
  $align-items: center,
  $justify-content: center
) {
  display: flex;
  flex-direction: $flex-direction;
  align-items: $align-items;
  justify-content: $justify-content;
}

// this should match the spacing variable used by MaterialUI https://mui.com/material-ui/customization/spacing/#main-content
$spacing: 8px;

@mixin ml($multiplier) {
  margin-left: calc($multiplier * $spacing) !important;
}

@mixin mt($multiplier) {
  margin-top: calc($multiplier * $spacing) !important;
}

@mixin mr($multiplier) {
  margin-right: calc($multiplier * $spacing) !important;
}

@mixin mb($multiplier) {
  margin-bottom: calc($multiplier * $spacing) !important;
}

@mixin mx($multiplier) {
  @include ml($multiplier);
  @include mr($multiplier);
}

@mixin my($multiplier) {
  @include mt($multiplier);
  @include mb($multiplier);
}

@mixin ma($multiplier-y, $multiplier-x) {
  margin: calc($multiplier-y * $spacing) calc($multiplier-x * $spacing) !important;
}

@mixin pl($multiplier) {
  padding-left: calc($multiplier * $spacing) !important;
}

@mixin pt($multiplier) {
  padding-top: calc($multiplier * $spacing) !important;
}

@mixin pr($multiplier) {
  padding-right: calc($multiplier * $spacing) !important;
}

@mixin pb($multiplier) {
  padding-bottom: calc($multiplier * $spacing) !important;
}

@mixin px($multiplier) {
  @include pl($multiplier);
  @include pr($multiplier);
}

@mixin py($multiplier) {
  @include pt($multiplier);
  @include pb($multiplier);
}

@mixin pa($multiplier-y, $multiplier-x) {
  padding: calc($multiplier-y * $spacing) calc($multiplier-x * $spacing) !important;
}

@mixin font-label {
  font-size: 14px;
  color: #666;
}

@mixin button-incognito {
  background: none;
  border: 0;
  border-radius: 0;
  cursor: pointer;
  outline: 0;
  text-transform: none;
  text-align: center;
  border-radius: 5px;
  padding: 0;

  &:focus {
    outline: 0;
  }
}
