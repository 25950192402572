@import './mixins.scss';

.container {
  @include flex(column);
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  &--inline {
    @include mb(5);
    border: 1px solid #D9D9D9;
    border-radius: 5px;
  }
}

.header {
  @include pa(1.5, 3);
  height: 75px;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid #D9D9D9;
  &__userDetails {
    @include flex(column, flex-start, center);
    height: 100%;
  }
  &__closeIcon {
    position: absolute;
    right: 20px;
    top: 22px;
    border-radius: 50%;
    @include flex();
    @include pa(0.5, 0.5);
    &:hover {
      cursor: pointer;
      background: #F5F5F7;
    }
  }
}

.body {
  @include pa(3, 3);
  width: 100%;
  box-sizing: border-box;

  &__closeWrapper {
    @include flex;
  }

  &__close {
    display: inline-block;
    margin: 0 auto;
    padding-top: 30px;
    padding-bottom: 10px;
    text-align: center;
    text-decoration: underline;
    cursor: pointer;
  }
}

.logo {
  @include mb(2);
}

.consent {
  &__noThanks {
    @include ml(2.5);
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  &__cta {
    @include mt(2.5);
    @include flex(row, center, flex-start);
  }
}

.options {
  @include mt(3.5);

  &__list {
    @include flex();
    flex-wrap:wrap;
  }

  &__item {
    @include mb(0.8);
    box-sizing: border-box;
    height: 32px;
    width: 48px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border: 1px solid #DFDFDE;
    border-radius: 3px;

    &:not(:last-of-type) {
      @include mr(0.8);
    }

    &:nth-of-type(5) {
      @include mr(0);
    }

    &[data-name="visa"] {
      background-size: 95%;
    }
    &[data-name="sepa"] {
      border: none;
      background-size: 95%;
    }
    &[data-name="klarna"] {
      background-size: 98%;
      background-color: #FFB3C7;
    }
    &[data-name="bankTransfer"] {
      background-size: 60%;
    }
    &[data-name="satispay"] {
      background-size: 95%;
    }
    &[data-name="amazonpay"],
    &[data-name="applepay"],
    &[data-name="googlepay"],
    &[data-name="sofort"] {
      border: none;
    }
  }
}

.otpLoader {
  @include flex();
  @include my(2);
}

.otpSuccess {
  @include flex();
  @include my(1);
}

.otpCode {
  font-family: 'Proxima Nova Monospaced' !important;
  height: 52px !important;
  font-size: 32px !important;
  max-height: unset;
  letter-spacing: 22px !important;
  padding: 8px 5px !important;
  /*Horizontal*/
  background-image: linear-gradient(
      to right,
      black 0%,
      black 13%,
      rgba(255, 255, 255, 0) 13%,
      rgba(255, 255, 255, 0) 17%,
      black 17%,
      black 29%,
      rgba(255, 255, 255, 0) 29%,
      rgba(255, 255, 255, 0) 33%,
      black 33%,
      black 45%,
      rgba(255, 255, 255, 0) 45%,
      rgba(255, 255, 255, 0) 49%,
      black 49%,
      black 61%,
      rgba(255, 255, 255, 0) 61%,
      rgba(255, 255, 255, 0) 65%,
      black 65%,
      black 77%,
      rgba(255, 255, 255, 0) 77%,
      rgba(255, 255, 255, 0) 81%,
      black 81%,
      black 93%,
      rgba(255, 255, 255, 0) 93%,
      rgba(255, 255, 255, 0) 97%
  ) !important;
  border-bottom: none !important;
  background-position: left bottom !important;
  background-size: 258px 1px !important;
  background-repeat: no-repeat !important;
  max-width: 260px !important;
  min-width: 260px !important;
  margin: auto !important;
}

.newOtpCode {
  font-family: 'Proxima Nova Monospaced' !important;
  height: 56px !important;
  font-size: 32px !important;
  max-height: unset;
  letter-spacing: 34.5px !important;
  padding: 0px 12px !important;
  /*Horizontal*/
  border-bottom: none !important;
  max-width: 326px !important;
  color: #5666F0;
}

.otpWrapper {
  @include my(3);
  width: 100%;
  height: 3.5rem;
}

.otpInput {
  font-family: 'Proxima Nova Monospaced' !important;
  width: 90% !important; 
  height: 100%;
  background: none;
  padding: 0.3rem;
  background: white;
  color: #111;
  font-size: 26px;
  font-weight: bold;
  border-radius: 0.5rem;
  border: 1px solid #ccc;
  transition: all 0.3s ease-in-out;
}

.otpInput--focus {
  border: 2px solid #000;
  outline: none;
}

.otpInput--error {
  &::selection {
    color: #111;
  }
}

.separator {
  width: 10px;
}
