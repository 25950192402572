@import './mixins.scss';

.item {
  @include flex(row, flex-start, flex-start);

  &:not(:last-of-type) {
    @include mb(3);
  }
}

.label {
  @include font-label;
  min-width: 80px;
}

.content {
  font-size: 14px;
  width: 100%;

  p {
    @include mb(1);
  }
}

.address {
  p:last-of-type {
    @include mb(0);
  }
}

.email,
.name {
  font-weight: bold;
}

.changeAddress,
.saveAddress,
.cancelAddress {
  @include button-incognito;
  font-size: 14px;
  font-weight: bold;
}

.savedAddress {
  @include flex(column, flex-start, space-between);
}

.changeAddress {
  @include mt(1.5);
  min-width: 50px;
}

.newAddress {
  @include mt(1);
}
@supports (-webkit-overflow-scrolling: touch) {
  select,
  textarea,
  input {
    font-size: 14px;
  }
}

.form {
  width: 100%;

  input,
  select {
    @include mb(1);
    height: 48px;
    width: 100%;
    border: 1px solid #a6a6a6;
    border-radius: 4px;
    padding: 10px;
    background: white;
    color: black;
    font-size: 14px;
    box-shadow: none;

    &:focus {
      border: 1px solid #a6a6a6;
      outline: none;
      box-shadow: none;
    }
  }

  .actions {
    @include flex(row, center, space-between);
    @include mt(1);
  }

  .cancelAddress {
    color: rgba(18, 19, 20, 0.5);
  }
}